<template>
<div class="table-content">
        <div class="table-options">
            <div class="delete" v-if="selected.length > 0">
                <vs-icon icon="delete"></vs-icon>
            </div>
        </div>
        <div class="table-wrapper with-border">
            <vs-table :data="bookings">
                <template slot="thead">
                    <vs-th sort-key="SrvT">Type</vs-th>
                    <vs-th sort-key="SrvDate">Date</vs-th>
                    <vs-th sort-key="SupCode">Supplier</vs-th>
                    <vs-th sort-key="invTotal">Status</vs-th>
                    <vs-th sort-key="SrvST">Description</vs-th>
                    <vs-th sort-key="CostSinIM">Cost</vs-th>
                    <vs-th sort-key="COSTTAX">TAX</vs-th>
                    <!-- <vs-th sort-key="Cost">Costo I.I</vs-th> -->
                    <vs-th sort-key="Voucher">Voucher</vs-th>
                    <vs-th sort-key="Income">Venta</vs-th>
                    <vs-th sort-key="MARGIN">Márgen</vs-th>
                    <vs-th sort-key="marginUSD">Márgen USD</vs-th>
                </template>
                <template slot-scope="{ data }">
                    <template :data="tr"  v-for="(tr, indextr) in data">
                        <vs-tr :key="indextr" :class="{'active': opened.includes(data[indextr].id)}">
                            <vs-td :data="data[indextr].SrvT"> 
                               <img class="icon-table" :src="require(`@/assets/images/booking/${data[indextr].SrvT}.svg`)" alt="" />
                            </vs-td>
                            <vs-td :data="data[indextr].SrvDate"> 
                                {{ data[indextr].SrvDate | moment('timezone', 'Etc/GMT', 'DD.MM.YYYY')}} 
                            </vs-td>
                            <vs-td :data="data[indextr].SupCode"> 
                                {{ data[indextr].SupCode}}
                            </vs-td>                           
                            <vs-td :data="data[indextr].SrvST"> 
                                {{ data[indextr].SrvST }}
                            </vs-td>                            
                            <vs-td :data="data[indextr].SrvDescription"> 
                                {{ data[indextr].SrvDescription }}
                            </vs-td>
                            <vs-td :data="data[indextr].CostSinIM"> 
                                {{ $n(data[indextr].CostSinIM, 'currency') }} 
                            </vs-td>
                            <vs-td :data="data[indextr].COSTTAX"> 
                                {{ $n(data[indextr].COSTTAX, 'currency') }} 
                            </vs-td>
                            <!-- <vs-td :data="data[indextr].Cost"> 
                                {{ $n(data[indextr].Cost, 'currency') }} 
                            </vs-td> -->
                            <vs-td :data="data[indextr].Voucher"> 
                                {{ data[indextr].Voucher}} 
                            </vs-td>
                            <vs-td :data="data[indextr].Income"> 
                                {{ $n(data[indextr].Income, 'currency') }} 
                            </vs-td>
                            <vs-td :data="data[indextr].MARGIN">
                                {{ $n(data[indextr].MARGIN, 'percent') }} 
                            </vs-td>
                            <vs-td :data="data[indextr].marginUSD"> 
                                {{ $n( data[indextr].marginUSD, 'currency') }} 
                            </vs-td>
                        </vs-tr>
                    </template>
                </template>
                <tr>
                    <td colspan=""></td>
                </tr>
            </vs-table>
        </div>
    </div>
</template>
<script>
export default {
    name: "TableServicesByBooking",
    props: {
        items: { type: Array, default: () => [] },
    },
    data () {
        return {
            opened: [],
            selected:[],
            bookings: this.items,
            balance: 0,
            invTotal: 0,
            rcTotal:  0
        }
    },
    watch: {
        items(val){
            this.bookings = val
            
        },
    },
    methods: {
        toggleRow(id){
            const index   = this.opened.indexOf(id)
            this.opened = []
            if (index > -1) {
                this.opened.splice(index, 1)
                this.activeRow = -1
            } else {
                this.opened.push(id)
                this.activeRow = id
            }
        }
    }
}
</script>