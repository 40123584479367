<template>
    <div class="dashboard bookings costSaleServices">
        <div class="head">
            <div class="title">
                <span>{{  title }}</span>
                <vs-button color="warning"  @click="reset()" type="filled" icon="backspace" v-if="this.services.length > 0"></vs-button>
            </div>
        </div>
        
        <div class="search-booking" v-if="this.services.length < 1">
            <div class="item">
                <vs-input label="Referencia" v-model="reference"/>
            </div>
            <div class="item">
                <vs-button color="primary"  @click="search()" type="filled" icon="search" :disabled="!this.reference != ''"></vs-button>
            </div>
        </div>

        <div class="services" v-if="this.services.length > 0">
            
            <div class="booking-info" v-if="this.services.length > 0">
                <div class="item">
                    <span>Nombre</span>
                    {{ bookingName }}
                </div>
                <div class="item">
                    <span>Agencia</span>
                    {{ bookingAgent }}
                </div>
                <div class="item">
                    <span>Agente</span>
                    {{ bookingVendor }}
                </div>
            </div>

            <div class="summary" v-if="totalCost > 0">
                <div class="item">
                    <div class="count">{{ $n(totalCost, 'currency') }}</div>
                    <div class="name">Cost</div>
                </div>
                <div class="item">
                    <div class="count">{{ $n(totalTax, 'currency') }}</div>
                    <div class="name">Tax</div>
                </div>
                <div class="item">
                    <div class="count">{{ $n(totalSell, 'currency') }}</div>
                    <div class="name">Sell</div>
                </div>
                <div class="item">
                    <div class="count">{{ $n(marginUsd, 'currency') }}</div>
                    <div class="name">Margin USD</div>
                </div>
            </div>

            <div class="title book-title">Servicios</div>

            <div class="bookings-wrapper">
                <table-services-booking :items="services"></table-services-booking>
            </div>

        </div>

        <modal :show="modalShow" :dark="true" title="Código Acceso">
            <div slot="modal-body">
                <div class="modal-content" style="padding: 35px 35px 15px">
                    <p>Por favor ingresar el código de acceso</p>
                    <br>
                    <vs-input v-model="codeAccess"  :danger="errorCode" danger-text="Código incorrecto" v-on:keyup.enter="validAccess()"/>
                </div>
            </div>
            <div slot="modal-footer">
                <div class="modal-footer">
                    <vs-button color="primary" type="border" @click="validAccess()" :disabled="disabled">Ingresar</vs-button>
                </div>
            </div>
        </modal>
        
    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
// import notResults from '@/components/NotResults.vue'
import tableServicesBooking from '@/components/Tables/ServicesByBooking.vue'
import BookingService from '@/services/BookingService'
import modal from "@/components/Modals/Basic.vue";
const  { mapActions: mapAppSettingActions } = createNamespacedHelpers('appSetting')

export default {
    name: "ServiceByBookinView",
    components: {
        modal,
        // notResults,
        tableServicesBooking,
    },
    data () {
        return {
            key: 1,
            date: "",
            reference: "",
            services: [],
            modalShow: true,
            codeAccess: "",
            errorCode: false,
            status: "",
            disabled: false,
            totalCost: 0,
            totalTax: 0,
            totalSell: 0,
            marginUsd: 0,
            bookingReference: "",
            bookingName: "",
            bookingAgent: "",
            bookingVendor: "",
            title: "Services by Booking"
            
        }
    },
    async created () {
        let code =  JSON.parse(localStorage.getItem('CDG'));
        this.codeAccess = code
        if(this.codeAccess != "" || this.disabled != undefined){
            this.validAccess();
        }
    },
    methods: {
        ...mapAppSettingActions(["LOADING_STATUS"]),
        async servicesByBooking(){            
            this.LOADING_STATUS(true);
            let response = await BookingService.getServicesByBooking({
                "reference" : this.reference
            });            
            if((response.status/100) == 2){
                this.services  = response.data
                if(this.services.length > 0){
                    this.totalCost = this.services[0].totalCost;
                    this.totalSell = this.services[0].totalSell;
                    this.totalTax  = this.services[0].totalTax;
                    this.marginUsd = this.services[0].totalMarginUsd;
                    this.bookingName   = this.services[0].name;
                    this.bookingAgent  = this.services[0].agent;
                    this.bookingVendor = this.services[0].vendor;
                    this.bookingReference = this.services[0].reference;
                    this.services  = this.services[0].services;
                    this.title = 'Booking - ' + this.bookingReference;
                }
            }            
            this.LOADING_STATUS(false);
        },
        search() {
            this.servicesByBooking()
        },
        reset() {
            this.reference = "";
            this.services  = [];
            this.title = "Services by Booking";
        },
        validAccess() {
            if(this.codeAccess == "asuaire2022") {
                this.modalShow = false
                this.status = "done"
                localStorage.setItem('CDG', JSON.stringify(this.codeAccess))
            } else {
                this.errorCode = true
            }
        },
    }
}
</script>